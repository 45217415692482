(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay-racing/views/item.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay-racing/views/item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.griditem_quickplay_racing=_svs.banner_layouts.griditem_quickplay_racing||{};
_svs.banner_layouts.griditem_quickplay_racing.templates=_svs.banner_layouts.griditem_quickplay_racing.templates||{};
svs.banner_layouts.griditem_quickplay_racing.templates.item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "  <div\n    class=\"banner-griditem-quickplay-racing banner-common-griditem f-content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":105}}})) != null ? stack1 : "")
    + "\"\n    data-impression=\"true\"\n    data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":48}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":10,"column":12}}})) != null ? stack1 : "")
    + ">\n    <div class=\"banner-background-clip\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0),"!==","0",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":26,"column":16}}})) != null ? stack1 : "")
    + "    <div class=\"banner-main-content banner-main-padding-0\">\n      <div class=\"banner-main-text "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBlackText") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":28,"column":35},"end":{"line":28,"column":86}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"high-five\">\n          <svg class=\"high-five-logo\" viewBox=\"0 0 207 60\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n            <path class=\"high-five-logo-color\" d=\"M16.0385 0.599951V25.4H9.23848V0.599951H0.438477V59H9.23848V34.2H16.0385V59H24.8385V0.599951H16.0385Z M29.116 0.599951V59H37.916V0.599951H29.116Z M53.1285 38.2H57.5285V50.04C57.5285 50.36 57.2885 50.6 56.9685 50.6H51.4485C51.2085 50.6 50.9685 50.36 50.9685 50.04V9.55995C50.9685 9.23995 51.2085 8.99995 51.4485 8.99995H56.8885C57.2085 8.99995 57.4485 9.23995 57.4485 9.55995V18.1999H66.2485V9.79995C66.2485 4.51995 62.0085 0.199951 56.6485 0.199951H51.7685C46.4885 0.199951 42.1685 4.51995 42.1685 9.79995V49.7999C42.1685 55.0799 46.4885 59.4 51.7685 59.4H56.6485C62.0085 59.4 66.3285 55.0799 66.3285 49.7999V29.4H53.1285V38.2Z M86.1335 0.599951V25.4H79.3335V0.599951H70.5335V59H79.3335V34.2H86.1335V59H94.9335V0.599951H86.1335Z M134.715 9.39995V0.599951H113.435V59H122.235V34.2H134.715V25.4H122.235V9.39995H134.715Z M138.988 0.599951V59H147.788V0.599951H138.988Z M171.96 0.599951L166.68 37.64H166.36L161.08 0.599951H151.24L161.64 59H171.48L181.8 0.599951H171.96Z M206.529 9.39995V0.599951H185.249V59H206.529V50.2H194.049V34.2H206.529V25.4H194.049V9.39995H206.529Z\" />\n          </svg>\n        </div>\n        <div class=\"banner-text-large notranslate\">"
    + alias4((lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"systemCost") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":34,"column":51},"end":{"line":34,"column":80}}}))
    + " kr</div>\n      </div>\n      <div class=\"banner-main-pix fc-white\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":53,"column":21}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"btn btn-300 btn-default banner-main-button","size":"300","block":"true"},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":66,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " banner-griditem-branding-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":61}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return " banner-griditem-no-branding";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-cmsid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":28}}}) : helper)))
    + "\"\n    data-cmstype=\"banner\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-landscape\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-portrait\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-griditem-gradient banner-griditem-gradient-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gradientOpacity") || (depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gradientOpacity","hash":{},"data":data,"loc":{"start":{"line":25,"column":66},"end":{"line":25,"column":85}}}) : helper)))
    + "\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "fc-black";
},"16":function(container,depth0,helpers,partials,data) {
    return "fc-white";
},"18":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"banner-pix-container banner-pix-two-lines\">\n            <div>\n              <span class=\"f-bold\">PIX!</span>\n              <span class=\"f-normal\">Färdiga&nbsp;spel.</span>\n            </div>\n            <div>\n              <span class=\"f-normal\">Helt&nbsp;enkelt.</span>\n            </div>\n          </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"banner-pix-container banner-pix-one-line\">\n            <span class=\"f-bold\">PIX!</span>\n            <span class=\"f-normal\">Färdiga&nbsp;spel.</span>\n            <span class=\"f-normal\">Helt&nbsp;enkelt.</span>\n          </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"regular-label\">Köp</span>\n          <span class=\"small-pix-label\">en high five pix!</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-header\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-tablet","heightMode":"tablet","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-desktop","heightMode":"desktop","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-quickplay-quickplay"),depth0,{"name":"components-banner_actions-quickplay-quickplay","hash":{"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"systemType":(depth0 != null ? lookupProperty(depth0,"systemType") : depth0),"systemCost":(depth0 != null ? lookupProperty(depth0,"systemCost") : depth0),"drawNumber":(depth0 != null ? lookupProperty(depth0,"drawNumber") : depth0),"rowPrice":(depth0 != null ? lookupProperty(depth0,"rowPrice") : depth0),"productId":(depth0 != null ? lookupProperty(depth0,"productId") : depth0),"branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-griditem_quickplay_racing-item'] = svs.banner_layouts.griditem_quickplay_racing.templates.item;
})(svs, Handlebars);


 })(window);