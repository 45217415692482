(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay-racing/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay-racing/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : svs.core;
const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-quickplay-racing');
const performanceLogger = getLogger('banner-grid:performance');
const currencyInMillions = svs.isServer ? trinidad.components.require('utils', 'format').api.CurrencyInMillions : svs.utils.format.CurrencyInMillions;
const {
  getFormattedDate,
  getFormattedTime
} = isServer ? trinidad.components.require('lb-utils', 'helper-formatdate').api : svs.components.lbUtils.helpers.formatDate;
const {
  TTLCacheAsync
} = isServer ? require('../../../../lb-utils/ttl-cache/controller.js') : svs.components.lbUtils.ttlCache;
const {
  resolveProductId,
  productIds
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  getRacingClassName
} = isServer ? trinidad.components.require('utils', 'racing') : svs.utils.racing;
const {
  getProductBrand
} = isServer ? trinidad.components.require('lb-utils', 'brand-mapping').api : svs.components.lbUtils.brandMapping.fn;
const {
  normalizeRacecardNext
} = isServer ? trinidad.components.require('racing', 'race-service').normalize : svs.racing.raceService.utils.normalize;
const ONE_MINUTE = 60 * 1000;
const TIMEOUT_FOR_JUPITER_CALLS = 3000;
const HIGH_FIVE_USER_PRODUCT = 'Rx5io';
const TAGLINE_JACKPOT = 'ca';
const HIGH_FIVE_ROW_PRICE = 20;
const mockableHelpers = Object.seal({
  getRacingData
});
const Global = Object.seal({
  racingCache: new TTLCacheAsync({
    onRefreshAsync: inputBag => mockableHelpers.getRacingData(inputBag),
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("racingCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  })
});

function formatAmountString(amount) {
  const amountString = currencyInMillions(amount);
  const suffix = parseFloat(amountString.replace(',', '.').replace(/\s/g, '')) !== 1 ? 'er' : '';
  return "".concat(amountString, " miljon").concat(suffix, " kr");
}

async function fetchJackpots(_ref) {
  var _jackpot$jackpots;
  let {
    raceId,
    req
  } = _ref;
  const path = '/racing/1/race/jackpots';
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    jackpots
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing racing jackpots  (raceId ".concat(raceId, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return null;
  }
  if (!Array.isArray(jackpots) || jackpots.length === 0) {
    logger.info("Missing \"jackpots\" in response from jackpots-API (product racing)");
    return null;
  }
  const jackpot = jackpots.find(jackpot => jackpot.raceId === raceId);
  const highFiveJackpot = jackpot === null || jackpot === void 0 || (_jackpot$jackpots = jackpot.jackpots) === null || _jackpot$jackpots === void 0 || (_jackpot$jackpots = _jackpot$jackpots.find(jp => jp.userProduct === HIGH_FIVE_USER_PRODUCT)) === null || _jackpot$jackpots === void 0 ? void 0 : _jackpot$jackpots.jackpot;
  return highFiveJackpot ? "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountString(highFiveJackpot)) : null;
}

async function getNextHighFive(_ref2) {
  var _meeting$track;
  let {
    req
  } = _ref2;
  const path = '/racing/1/racecard/nexthighfive';
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error
  } = isServer ? response : response.response;
  const productName = getRacingClassName(productIds.RACING, req);
  if (error) {
    logger.info("API-error while listing nexthighfive (product ".concat(productName, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  const normalized = normalizeRacecardNext(isServer ? response : response.response);
  const {
    draws: currentDraws,
    raceId,
    startTime: startTimeTentative,
    meeting,
    countryCode
  } = normalized || {};
  if (!Array.isArray(currentDraws) || currentDraws.length === 0) {
    logger.info("Missing \"draws\" in response from nexthighfive-API (product ".concat(productName, ")"));
    return {};
  }
  const currentDraw = currentDraws.find(draw => draw.userProduct === HIGH_FIVE_USER_PRODUCT);
  const trackName = meeting === null || meeting === void 0 || (_meeting$track = meeting.track) === null || _meeting$track === void 0 ? void 0 : _meeting$track.trackName;
  return {
    currentDraw,
    raceId,
    startTimeTentative,
    trackName,
    countryCode
  };
}

async function getRacingData(_ref3) {
  let {
    payload
  } = _ref3;
  const {
    product,
    req
  } = payload;
  const {
    currentDraw,
    raceId,
    startTimeTentative,
    trackName,
    countryCode
  } = await getNextHighFive({
    product,
    req
  });
  if (!currentDraw) {
    return {};
  }
  const jackpotAmountString = await fetchJackpots({
    raceId,
    req
  });
  const formattedDate = getFormattedDate(startTimeTentative, true);
  const formattedTime = getFormattedTime(startTimeTentative);
  return {
    currentDraw,
    jackpotAmountString,
    raceId,
    formattedDate,
    formattedTime,
    trackName,
    countryCode
  };
}

async function renderComponent(bannerItem, widgetInfo, position, req, res) {
  var _bannerItem$images, _bannerItem$images2, _cachedData$currentDr, _svs;
  const {
    size,
    product,
    layoutVariant
  } = bannerItem.layoutConfig;
  const systemCost = parseFloat(size === null || size === void 0 ? void 0 : size.replace(',', '.')) || 100;
  const hasBlackText = String(layoutVariant).includes('black-text');
  const hasImage = String(layoutVariant).includes('with-image');
  const landscapeImage = hasImage ? (_bannerItem$images = bannerItem.images) === null || _bannerItem$images === void 0 ? void 0 : _bannerItem$images.filter(item => item.typeName === 'productImage')[0] : null;
  const portraitImage = hasImage ? (_bannerItem$images2 = bannerItem.images) === null || _bannerItem$images2 === void 0 ? void 0 : _bannerItem$images2.filter(item => item.typeName === 'productImageOptional')[0] : null;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-griditem_quickplay_racing-item'] :
  svs.banner_layouts.griditem_quickplay_racing.templates.item;
  const isAdmin = isServer ?
  req.userSession.hasRole(req.userSession.roles.INTERNAL) :
  svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  const trackingStringWithFixedStructure = [widgetInfo.widgetType, widgetInfo.trackingId || widgetInfo.widgetName, position, bannerItem.layoutType, bannerItem.title].join('/');
  const t0 = performance.now();
  const cacheKey = widgetInfo.unitTestId ? "".concat(product, "_pix_auto_").concat(widgetInfo.unitTestId) : "".concat(product, "_pix_auto");
  const cachedData = await Global.racingCache.readAsync({
    key: cacheKey,
    payload: {
      product,
      req
    }
  });
  performanceLogger.info("racingCache.readAsync() took ".concat(Math.round(performance.now() - t0), " milliseconds (index ").concat(position - 1, ")"));
  const productId = resolveProductId(product);
  const drawNumber = cachedData === null || cachedData === void 0 || (_cachedData$currentDr = cachedData.currentDraw) === null || _cachedData$currentDr === void 0 ? void 0 : _cachedData$currentDr.drawNumber;
  const rowPrice = HIGH_FIVE_ROW_PRICE;
  const systemType = HIGH_FIVE_USER_PRODUCT;
  if (!productId || !drawNumber) {
    return '';
  }
  let productBranding = getProductBrand(productId);
  if (product.includes('-secondary')) {
    productBranding += '-secondary';
  }
  return hbsCompiled(_objectSpread(_objectSpread({}, bannerItem.layoutConfig), {}, {
    isAdmin,
    bannerId: bannerItem.id,
    branding: productBranding,
    features: isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features,
    productId,
    drawNumber,
    systemCost,
    systemType,
    hasBlackText,
    landscapeImage,
    portraitImage,
    rowPrice,
    trackingString: trackingStringWithFixedStructure,
    rows: Math.floor(systemCost / rowPrice),
    crm: bannerItem.targetOffer
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.griditem_quickplay_racing', {
    renderComponent
  });

  const isTestEnvironment = typeof sinon !== 'undefined' && typeof describe !== 'undefined';
  if (isTestEnvironment) {
    setGlobal('svs.banner_layouts.griditem_quickplay_racing._testInternals', {
      getMockableHelpers: () => mockableHelpers
    });
  }
}

 })(window);